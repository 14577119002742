@font-face {
  font-family: post;
  src: url("../res/fonts/PostNoBillsJaffna-ExtraBold.woff");
}

@font-face {
  font-family: Poppins;
  src: url("../res/fonts/Poppins-Regular.ttf");
}

* {
  margin: 0;
  padding: 0;
  /* box-sizing: border-box; */
}
*::-webkit-scrollbar {
  display: none;
}

.body {
  background-color: black;
  /* color: beige; */
  padding: 3rem 2rem;
  font-family: Poppins;
}
body::-webkit-scrollbar {
  display: none;
}
.hero {
  display: flex;
  justify-content: space-between;
  align-content: center;
  /* vertical-align: middle; */
  width: 100%;
  box-sizing: border-box;
}
.introname {
  color: white;
  line-height: 5.5rem;
  font-size: 2rem;
  cursor: default;
  font-weight: 500;
  font-family: post;
  font-size: 4rem;
  font-style: normal;
  font-weight: 800;
  overflow: hidden;
}
.basicinfo {
  line-height: 3.5rem;
  padding: 1.5rem 0;
}
.goAbout {
  background-color: darkred;
  text-align: center;
  border: none;
  outline: none;
  padding: 0.5rem 3rem;
  color: white;
  font-size: 1.5rem;
  font-style: normal;
  font-weight: 700;
}
.sHeader {
  font-family: post;
  font-size: 2.5rem;
  font-style: normal;
  font-weight: 800;
  width: fit-content;
  margin: auto;
}
.about {
  padding: 2rem 0;
  color: white;
  font-weight: 400 !important;
  /* font-size: 1.5rem; */
  line-height: normal !important;
}
.espan{
  font-weight: 400;
  font-size: 1.5rem;
  line-height: 1.5rem;
}
@keyframes bounces {
  0% {
    font-size: 4rem;
  }
  16.66% {
    font-size: 3rem;
  }
  33.32% {
    font-size: 5rem;
  }
  49.98% {
    font-size: 4rem;
  }
  66.64% {
    font-size: 5rem;
  }
  83.30% {
    font-size: 4.5rem;
  }
  100% {
    font-size: 5rem;
  }
}
.heropicbg2 {
  display: none;
}
.heropicbg,
.heropicbg2 {
  background-color: white;
  border-radius: 50%;
  height: 18.9375rem;
  width: 18.9375rem;
  overflow: hidden;
  text-align: center;
  /* scale: ; */
}
/* .heropicbg:hover {
  transform: scale(1.3);
  translate: -2.5rem 1rem;
  transition-duration: 0.3s;
  transform-origin: center;
  transform-style: preserve-3d;
  cursor: none;
  text-align: center;
} */
.heropicbg img,
.heropicbg2 img {
  width: 19rem;
}
dd {
  scale: 0 !important;
}
.dd:hover {
  color: darkred;
  cursor: none;
  animation: bounces ease-in-out;
  animation-duration: 1s;
  font-size: 5rem;
  /* animation-iteration-count: 2; */
}
.dd::after {
  color: darkred;
  cursor: none;
  animation: bounces ease-in-out;
  animation-duration: 1s;
  font-size: 5rem;
  /* animation-iteration-count: 2; */
}
.skillsdetail {
  color: white;
  display: flex;
  justify-content: space-between;
  line-height: 3rem;
  vertical-align: middle;
  align-content: center;
  align-items: center;
  height: fit-content !important;
  /* overflow: hidden; */
}
.rated {
  width: 45%;
  overflow: hidden;
}
.rated small {
  display: flex;
  align-items: center;
  justify-content: space-between;
  vertical-align: middle;
}
.decoHr {
  border: solid thin darkred;
  width: 60%;
  height: 0;
  border-bottom: none;
  margin: 0 1rem;
}
.rated ul {
  list-style: none;
  gap: 1.8rem;
}
.rated small {
  font-size: 1rem;
}
.iconStar {
  width: 2.08331rem;
  height: 1.97919rem;
  padding: 0;
  margin: 0;
}
/* .dodimg {
  width: 473px;
  height: 483px; 
} */
.iconStar:hover {
  transform: scale(1.2);
  transition-duration: 0.45s;
}
.stars {
  display: flex;
  flex-wrap: nowrap;
  padding: 0 1.5rem;
  gap: 1.1rem;
  overflow: hidden;
}
.rated ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 1.5rem;
  font-style: normal;
  align-items: center;
  vertical-align: middle;
  align-content: center;
  font-weight: 500;
}
.services {
  width: 55%;
  line-height: normal;
  height: fit-content !important;
  text-align: center;
}
.servicesmall {
  text-align: center;
  font-weight: lighter;
  word-spacing: 0.2rem;
  margin-bottom: 1rem !important;
}
.sLink {
  text-align: center;
  width: 40% !important;
  color: white;
  display: flex;
  flex-wrap: wrap;
  padding: 0.9rem 0;
  text-decoration: none;
  overflow: hidden;
  gap: 1.25rem;
  border-radius: 0.5rem;
}
.ow {
  line-height: normal !important;
  display: block;
  width: 100%;
}
.icon {
  height: 2.32144rem;
  /* display: block; */
  margin: 0;
  margin: auto;
  width: 21.60713rem !important;
}
.slinks {
  display: flex;
  justify-content: space-evenly;
  width: 100% !important;
  flex-flow: wrap;
  margin-top: 1rem;
  box-sizing: border-box;
}
.sLink:hover {
  transform: scale(1.1);
  transition-duration: 0.55s;
  transform-origin: center;
  border-radius: 0.625rem;
  border: 1px solid #fff;
  /* z-index: 999; */
  background: #161616;
  /* outline: solid rgba(138, 207, 235, 0.5); */
}
.slinksmobile {
  display: none;
}

@media (max-width: 450px) {
  @keyframes bounces {
    0% {
      font-size: 3rem;
    }
    16.66% {
      font-size: 2rem;
    }
    33.32% {
      font-size: 4rem;
    }
    49.98% {
      font-size: 3rem;
    }
    66.64% {
      font-size: 4rem;
    }
    83.30% {
      font-size: 3.5rem;
    }
    100% {
      font-size: 4rem;
    }
  }
  .body {
    padding: 1rem 1rem;
    padding-top: 1.5rem;
  }
  .slinks {
    display: none;
  }
  .hobb {
    font-size: 1.2rem;
    line-height: 1.7rem;
    margin-bottom: 0.5rem;
  }
  .slinksmobile {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  body {
    width: 100vw;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }

  .hero {
    position: relative;
  }
  .about {
    width: 100%;
    text-align: justify;
    text-justify: distribute;
  }
  .basicinfo {
    width: 100%;
    text-align: center;
  }
  .navbtn {
    color: white;
    font-weight: 700;
    font-size: 1rem;
    gap: 4rem;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    outline: solid thin white;
    width: fit-content;
    margin: 3rem auto !important;
    margin-top: 3rem;
    text-decoration: none;
    background-color: black;
  }
  /* .about br:first-child{
            display: none;
        } */
  .heropicbg {
    display: none;
  }
  .heropicbg2 {
    display: block;
    margin: 1rem auto;
    height: 14.9375rem;
    width: 14.9375rem;
    /* margin-bottom: 0.7rem; */
  }
  .heropicbg2 img {
    width: 15rem;
  }
  .introname {
    width: 100%;
    text-align: center;
    font-size: 2.4rem;
    line-height: 3.5rem;
    font-weight: 500;
  }
  .dd:hover {
    font-size: 4rem;
  }
  .about {
    font-size: 1.5rem;
    text-align: left;
  }
  .about br {
    display: none;
  }
  .about br:nth-child(2),
  .about br:nth-child(3) {
    display: block;
  }
  .stars {
    padding: 0 1rem;
    gap: 0rem;
  }
  .heropicbg:hover {
    transform: none;
    scale: 1;
    transition: 0rem 0rem !important;
    translate: 0;
  }
  .skillsdetail {
    flex-wrap: wrap !important;
  }
  .rated {
    width: 100%;
    overflow: hidden;
    margin: auto;
  }
  .rated ul {
    list-style: none;
    gap: 1.8rem;
  }
  .rated ul li strong {
    font-size: 1rem;
  }
  .services {
    width: 100%;
    margin: auto;
  }
  .sLink {
    width: 70% !important;
  }
}

@media (min-width: 451px) and (max-width: 1024px) {
  /* .body {
    padding: 1.5rem 1rem;
  } */
  @keyframes bounces {
    0% {
      font-size: 4rem;
    }
    16.66% {
      font-size: 3rem;
    }
    33.32% {
      font-size: 5rem;
    }
    49.98% {
      font-size: 4rem;
    }
    66.64% {
      font-size: 5rem;
    }
    83.30% {
      font-size: 4.5rem;
    }
    100% {
      font-size: 5rem;
    }
  }
  body {
    width: 100vw;
    margin: 0;
    border: 0;
    box-sizing: border-box;
  }
  .body {
    padding: 1rem 2.9rem;
    padding-top: 1.5rem;
  }
  .slinks {
    display: none;
  }
  .introname {
    width: 100%;
    text-align: center;
    font-size: 4rem;
    line-height: 6rem;
    font-weight: 500;
  }
  .dd:hover {
    font-size: 5rem;
  }
  .hero {
    position: relative;
  }
  .about {
    width: 100%;
    font-size: 1.5rem;
    text-align: left;
  }
  .about br {
    display: none;
  }
  .about br:nth-child(2),
  .about br:nth-child(3) {
    display: block;
  }
  .slinksmobile {
    display: block;
    width: 100%;
    text-align: center;
    margin-top: 1rem;
  }
  .basicinfo {
    width: 100%;
    text-align: center;
    position: static;
  }
  .heropicbg {
    display: none;
  }
  /* .about br:first-child{
              display: none;
          } */
  .heropicbg2 {
    display: block;
    margin: 1rem auto;
    height: 18.9375rem;
    width: 18.9375rem;
  }
  .heropicbg:hover {
    transform: none;
    scale: 1;
    transition: 0rem 0rem !important;
    translate: 0;
  }
  .skillsdetail {
    flex-wrap: wrap !important;
  }
  .rated {
    width: 100%;
    margin: auto;
  }
  .services {
    width: 100%;
  }
  .sLink {
    /* height: ; */
    width: 40% !important;
  }
  .hobb {
    font-size: 1.5rem;
    line-height: 1.7rem;
    margin-bottom: 0.5rem;
  }
  .navbtn {
    color: white;
    font-weight: 700;
    font-size: 1.5rem;
    gap: 4rem;
    padding: 0.5rem 1rem;
    border-radius: 0.3rem;
    outline: solid thin white;
    width: fit-content;
    margin: 3rem auto !important;
    margin-top: 3rem;
    text-decoration: none;
    background-color: black;
  }
}
