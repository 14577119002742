*
    box-sizing: border-box
    
.footer
    width: 100%

.regmail
    width: fit-content
    margin: auto
    padding-top: 1.5rem
    padding-bottom: 3rem
    text-align: center 


.regmail p
    font-family: Poppins
    font-size: 1.5rem
    width: 39.19rem
    font-weight: 400
    line-height: 2.2rem
    text-align: center

.reginput
    width: 27rem
    height: 5rem
    font-size: 1.4rem
    font-weight: 400
    line-height: 25rem
    text-align: left
    padding: 1.4rem 0.4rem 1.4rem 0.4rem
    border-radius: 0.5rem
    box-shadow: -1px 3px 3px 0px #00000040
    background: #D9D9D9
    border: 1px solid #C8C8C8
    &:focus
        box-shadow: -1px 3px 3px 0px #00000040
        border: 1px solid #292929

.erromail
    width: 27rem
    display: flex
    justift-content: space-between
    justify-items: center
    vertical-align: middle
    align-content: center
    align-items: center
    margin: auto
    font-size: 1.3rem
    font-weight: 400
    line-height: 1.8rem
    text-align: left
    display: block
    color: #A33232


.regbtn,
    width: fit-content
    height: fit-content
    margin-top: 1.5rem
    padding: 1rem 2rem
    border-radius: 0.5rem
    background: #161616
    color: white
    font-size: 1.5rem
    font-weight: 400
    line-height: 2.2rem
    outline: none
    box-shadow: none
    border: none
    &:hover
        background: #161616CC


.socialMedia img
    width: 2.5rem
    height: 2.5rem
    margin: 0



.socialMediacon
    width: fit-content
    align-content: center
    line-height: 2rem

.socialMediacon h3
    font-size: 1.3rem
    font-weight: 400
    line-height: 2rem
    text-align: left
    display: block
    width: fit-content

.socialMediacon div
    width: 100%
    display: flex
    justify-content: space-between


.realfooter
    width: 100%
    display: flex
    align-items: center
    vertical-align: middle
    justify-content: space-between
    background-color: black
    padding: 1rem 2.5rem
    color: white

.realfooter div:first-child
        font-size: 1.5rem

.realfooter div:first-child div img
    width:  1.5rem
        


.footernav
    display: flex
    justify-content: space-between
    width: 100%

.navlinkF
    width: fit-content
    height: fit-content
    margin: 0.5rem
    padding: 1rem
    border-radius: 0.5rem
    background: #382d2d
    color: white
    font-size: 1rem
    font-weight: 400
    line-height: 2.2rem
    outline: none
    text-decoration: none
    border: none
    &:hover
        background: #161616CC

.navlinkF img
    width: 1rem


.contacticon
    width: 1rem
    height: 1rem

@media (max-width:500px)
    .regmail
        width: 80vw
        margin: auto
        padding-top: 1rem
        padding-bottom: 1.5rem
        text-align: center 


    .regmail p
        font-family: Poppins
        font-size: 1rem
        width: fit-content
        font-weight: 400
        line-height: 1.5rem
        text-align: center

    .reginput
        width: 70vw
        height: 3rem
        font-size: 1.2rem
        font-weight: 400
        line-height: 25rem
        text-align: left
        padding: 0.6rem 0.4rem 0.6rem 0.4rem
        border-radius: 0.5rem
        box-shadow: -1px 3px 3px 0px #00000040
        background: #D9D9D9
        border: 1px solid #C8C8C8
        &:focus
            box-shadow: -1px 3px 3px 0px #00000040
            border: 1px solid #292929

    .erromail
        width: 70vw
        margin: auto
        font-size: 1.3rem
        font-weight: 400
        line-height: 1.8rem
        text-align: left
        display: block
        color: #A33232


    .regbtn
        width: fit-content
        height: fit-content
        margin-top: 0.5rem
        padding: 1rem 2rem
        border-radius: 0.5rem
        background: #161616
        color: white
        font-size: 1.5rem
        font-weight: 400
        line-height: 2rem
        outline: none
        box-shadow: none
        border: none
        &:hover
            background: #161616CC




    .realfooter
        display: block
        width: 100%
        box-sizing: border-box


    .realfooter div:first-child
        display: none

    .socialMediacon
        width:  fit-content
        margin: auto

    .socialMediacon h3
        display: none

    .socialMediacon div
        width: 50vw
        display: flex
        justtify-content: space-evenly

@media (min-width:501px) and (max-width:1000px)
    .regmail
        width: 60vw
        margin: auto
        padding-top: 1rem
        padding-bottom: 1.5rem
        text-align: center 


    .regmail p
        font-family: Poppins
        font-size: 1rem
        width: fit-content
        font-weight: 400
        line-height: 1.5rem
        text-align: center

    .reginput
        width: 90%
        height: 3rem
        font-size: 1.2rem
        font-weight: 400
        line-height: 25rem
        text-align: left
        padding: 0.6rem 0.4rem 0.6rem 0.4rem
        border-radius: 0.5rem
        box-shadow: -1px 3px 3px 0px #00000040
        background: #D9D9D9
        border: 1px solid #C8C8C8
        &:focus
            box-shadow: -1px 3px 3px 0px #00000040
            border: 1px solid #292929

    .erromail
        width: 70vw
        margin: auto
        font-size: 1.3rem
        font-weight: 400
        line-height: 1.8rem
        text-align: left
        display: block
        color: #A33232


    .regbtn
        width: fit-content
        height: fit-content
        margin-top: 0.5rem
        padding: 1rem 2rem
        border-radius: 0.5rem
        background: #161616
        color: white
        font-size: 1.5rem
        font-weight: 400
        line-height: 2rem
        outline: none
        box-shadow: none
        border: none
        &:hover
            background: #161616CC


    .realfooter
        width:  100%
        box-sizing: border-box