.nfthero
    display: flex
    width: 100%
    padding: 1rem 2rem
    padding-top: 1rem
    justify-content: space-between
    vertical-align: middle
    align-content: center
    align-items: center

.nftherodis
    font-size: 2.5rem
    font-weight: 600
    line-height: 3.7rem
    // letter-spacing: 0em
    text-align: left


.zapdevon
    display: flex
    font-size: 1.5rem
    font-weight: 400
    line-height: 2.2rem
    padding: 0.8rem
    border-radius: 0.8rem
    border: none
    outline: none
    background-color: black
    color: white
    align-content: center
    vertical-align: middle
    align-items: center
    margin-top: 2rem

.zapdevon img
    width: 2rem
    height: 2rem
    margin-left: 0.5rem
    

.nftCimg
    width: 29.8rem
    height: 29.8rem


.nftcaro
    width: 40%
    height: fit-content
    text-align: center



#carouselContainers
    // width: 100%
    // height: 50vh
    display: flex
    overflow: hidden !important


#carouselimg
    // width: 100%
    // height: 30vh
    flex: 0 0 auto

#carousel
    display: flex
    width: 100%
    height: 40vw
    position: relative
    overflow: hidden !important

.carousel-items
    opacity: 0
    position: absolute
    top: 0
    // left: 0
    transition: opacity 1s ease-in-out

.carousel-items.active
    opacity: 1 


@media (max-width:500px)
    .nfthero
        flex-flow: wrap
        width: 100%

    .nftherodis br::nth-child(2)
        display: none

    .nftherodis
        font-size: 2rem
        font-weight: 600
        width: 100%
        line-height: 3rem

    .zapdevon
        margin: 2rem auto
        
    .nftcaro
        width: 100%
        text-align: center

    .nftCimg
        width: 12.125rem
        height: 12.125rem
        margin: auto


    #carousel
        width: 100%
        height: 40vh

    #carousel img
        width: 100%
        // flex: 0 0 auto


@media (min-width:501px) and ( max-width:850px  )

    .nfthero
        display: block
        width: fit-content
        margin: auto

    .nftherodis br::nth-child(2)
        display: none

    .nftherodis
        font-size: 2.5rem
        font-weight: 600
        width: fit-content
        margin: auto
        line-height: 3rem

    .zapdevon
        margin: 2rem auto
        
    .nftcaro
        width: 100vw
        text-align: center
        

    .nftCimg
        width: 12.125rem
        height: 12.125rem
        margin: auto

    #carousel
        width: 100%
        height: 40vh


    