$font-color:    white
$main-color:    black
$sub-color:     darkred
$header-width:  1rem



=header-text($fs, $fw, $line-h)
    font-weight:        $fw
    font-size:          $fs
    line-height:        $line-h

=container($width, $height, $b-rad, $mg, $pad)
    width:          $width
    height:         $height
    border-radius:  $b-rad
    margin:         $mg
    padding:        $pad

// *
//     overflow-x: hidden !important

.crobody
    padding-top: 3rem
    width: 100vw
    


.header-sub
    margin: auto
    text-align: center
    width: fit-content
    padding: 1rem 0
    font-weight: 600
    font-size: 2.5rem
    
.carosection
    width: 100%
    // height: 30.875rem
    text-align: center

.dodocontain
    width: 100%
    padding: 0 1.5rem
    display: flex
    margin-bottom: 1.5rem
    justify-content: space-between
    vertical-align: middle
    align-content: center
    align-items: center

.carosection
    position: relative
    widows: 100%


.underlay
    width: 100vw
    height: 28rem

.headerex
    width: 100%
    text-align: center
    font-family: Poppins
    font-size: 2.5rem
    font-weight: 700
    line-height: 3.7rem
    color: #A33232

.visnone
    visibility: 0
    // background-color: transparentize(0)
    background-color: transparent !important

.saydodo
    padding: 2rem 1rem
    border-radius: 1rem
    background: #1616160F

    width: 60%
    font-weight: 400
    line-height: 2rem
    height: fit-content
    font-size: 1.5rem

.dodoimg
    width: 35%
    height: 29.56rem

.dodimg
    width: 29.56rem
    height: 29.56rem

=shaped($height, $width, $rad, $bg, $color)
    height:             $height
    width:              $width
    border-radius:      $rad
    background-color:   $bg
    color:              $sub-color


.expe
    +container(100%, fit-content, 0rem, auto, 1rem)
    // background: #1616160F
    display: flex
    flex-wrap: wrap
    justify-content: space-evenly
    // justify-items: 
    gap: 2rem
    float: left !important
    
    



.expes
    +container(35.19rem, 20.56rem, 1.5rem, auto, 1rem 2rem)
    font-weight: 400
    font-size: 1.5rem
    // line-height: 2.5rem
    display: flex
    vertical-align: middle
    align-content: center
    align-items: center
    justify-content: center
    background: #1616160F
    flex-flow: wrap
    float: left


.expes h2
    width: 100%
    text-align: left
    // font-weight: 400
    font-size: 1.5rem

    // *
    //     overflow-x: hidden !important
@media (max-width:450px) 
    .crobody
        width: 100%
        margin-left: 0
        margin-right: 0
        box-sizing: border-box



    .dodocontain
        display: flex
        flex-flow: wrap
        padding: 1rem 1.5rem
        width: 100%
        box-sizing: border-box
        margin: 0.5rem auto

    .do2
        flex-flow: wrap-reverse

    .saydodo
        width: 100%
        margin: auto
        font-size: 1rem
        line-height: 1.5rem

    .dodimg
        width: 14.063rem
        height: 14.375rem
        margin: 1rem auto
        padding: 0
        
        
        
    .visnone
        display: none
    
    

@media (min-width:450px)  and (max-width:850px)
    .crobody
        width: 100%
        margin-left: 0
        margin-right: 0
        box-sizing: border-box



    .dodocontain
        display: flex
        flex-flow: wrap
        padding: 1.5rem 2.5rem
        width: 100%
        box-sizing: border-box
        margin: 0.5rem auto

    .do2
        flex-flow: wrap-reverse

    .saydodo
        width: 100%
        margin: auto
        font-size: 1.5rem
        font-width: 400
        line-height: 2.2rem

    .dodimg
        width: 21.313rem
        height: 21.75rem
        margin: 1rem auto
        padding: 0
        
    .expes
        width: 45%
    
    